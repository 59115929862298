// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HistoryCommand-modules__wrapper___J4qkY{display:-webkit-box;display:-ms-flexbox;display:flex;gap:20px}.HistoryCommand-modules__reloadImg___kmcNE{width:16px;height:16px}.HistoryCommand-modules__reloadImg___kmcNE.HistoryCommand-modules__reverse___TtLSy{-webkit-transform:scaleX(-1);transform:scaleX(-1)}", "",{"version":3,"sources":["webpack://./src/features/studio/components/HistoryCommand/HistoryCommand.modules.scss"],"names":[],"mappings":"AAAA,yCACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,QAAA,CAGF,2CACE,UAAA,CACA,WAAA,CAEA,mFACE,4BAAA,CAAA,oBAAA","sourcesContent":[".wrapper {\n  display: flex;\n  gap: 20px;\n}\n\n.reloadImg {\n  width: 16px;\n  height: 16px;\n\n  &.reverse {\n    transform: scaleX(-1)\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "HistoryCommand-modules__wrapper___J4qkY",
	"reloadImg": "HistoryCommand-modules__reloadImg___kmcNE",
	"reverse": "HistoryCommand-modules__reverse___TtLSy"
};
export default ___CSS_LOADER_EXPORT___;
