// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SenseButton-module__wrapper___KLjA5{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center}.SenseButton-module__sensePlaceholder___YUvFw{display:-webkit-box;display:-ms-flexbox;display:flex;width:30px;height:20px;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center}", "",{"version":3,"sources":["webpack://./src/features/sense/ui/SenseButton/SenseButton.module.scss"],"names":[],"mappings":"AAAA,qCACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CAGF,8CACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,UAAA,CACA,WAAA,CACA,uBAAA,CAAA,oBAAA,CAAA,sBAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA","sourcesContent":[".wrapper {\n  display: flex;\n  align-items: center;\n}\n\n.sensePlaceholder {\n  display: flex;\n  width: 30px;\n  height: 20px;\n  justify-content: center;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "SenseButton-module__wrapper___KLjA5",
	"sensePlaceholder": "SenseButton-module__sensePlaceholder___YUvFw"
};
export default ___CSS_LOADER_EXPORT___;
