// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Header-module__wrapper___cIo8v{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;position:sticky;top:0;padding:15px;padding-bottom:0;z-index:4}.Header-module__wrapper___cIo8v::before{content:\"\";height:100%;width:110%;position:absolute;top:-5px;z-index:-1;left:-5%;-webkit-filter:blur(5px);filter:blur(5px);background:-webkit-gradient(linear, left top, left bottom, color-stop(82.81%, rgba(0, 0, 0, 0.92)), to(rgba(0, 0, 0, 0)));background:linear-gradient(180deg, rgba(0, 0, 0, 0.92) 82.81%, rgba(0, 0, 0, 0) 100%)}.Header-module__wrapper___cIo8v.Header-module__scroll___WSG02::before{background-color:#000}", "",{"version":3,"sources":["webpack://./src/containers/application/Header/Header.module.scss"],"names":[],"mappings":"AAAA,gCACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,6BAAA,CACA,eAAA,CACA,KAAA,CACA,YAAA,CACA,gBAAA,CACA,SAAA,CAEA,wCACE,UAAA,CACA,WAAA,CACA,UAAA,CACA,iBAAA,CACA,QAAA,CACA,UAAA,CACA,QAAA,CACA,wBAAA,CAAA,gBAAA,CACA,yHAAA,CAAA,qFAAA,CAQA,sEACE,qBAAA","sourcesContent":[".wrapper {\n  display: flex;\n  justify-content: space-between;\n  position: sticky;\n  top: 0;\n  padding: 15px;\n  padding-bottom: 0;\n  z-index: 4;\n\n  &::before {\n    content: '';\n    height: 100%;\n    width: 110%;\n    position: absolute;\n    top: -5px;\n    z-index: -1;\n    left: -5%;\n    filter: blur(5px);\n    background: linear-gradient(\n      180deg,\n      rgb(0 0 0 / 92%) 82.81%,\n      rgb(0 0 0 / 0%) 100%\n    );\n  }\n\n  &.scroll {\n    &::before {\n      background-color: #000;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "Header-module__wrapper___cIo8v",
	"scroll": "Header-module__scroll___WSG02"
};
export default ___CSS_LOADER_EXPORT___;
