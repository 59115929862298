// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Studio-module__wrapper___UVrrV{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;height:inherit;min-height:200px;gap:15px}.Studio-module__wrapper___UVrrV>:first-child>div{overflow:hidden}.Studio-module__containerEditor___snVph{display:grid;height:100%;grid-gap:20px;gap:20px;grid-template-columns:150px 1fr 150px}.Studio-module__containerEditor___snVph>:nth-child(2)>div{overflow:hidden}", "",{"version":3,"sources":["webpack://./src/features/studio/Studio.module.scss"],"names":[],"mappings":"AAIA,gCACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,2BAAA,CAAA,4BAAA,CAAA,yBAAA,CAAA,qBAAA,CACA,cAAA,CACA,gBAAA,CACA,QAAA,CAME,iDAEE,eAAA,CAKN,wCACE,YAAA,CACA,WAAA,CAEA,aAAA,CAAA,QAAA,CACA,qCAAA,CAGE,0DAEE,eAAA","sourcesContent":["@import '../../layouts/variables.module';\n\n$controlPanelHeight: 60px;\n\n.wrapper {\n  display: flex;\n  flex-direction: column;\n  height: inherit;\n  min-height: 200px;\n  gap: 15px;\n  // min-height: calc(\n  //   100vh - $reservedTopHeight - $actionBarHeight - $controlPanelHeight\n  // );\n\n  > :first-child {\n    > div {\n      // don't understand this scroll\n      overflow: hidden;\n    }\n  }\n}\n\n.containerEditor {\n  display: grid;\n  height: 100%;\n  // padding: 20px;\n  gap: 20px;\n  grid-template-columns: 150px 1fr 150px;\n\n  > :nth-child(2) {\n    > div {\n      // don't understand this scroll\n      overflow: hidden;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "Studio-module__wrapper___UVrrV",
	"containerEditor": "Studio-module__containerEditor___snVph"
};
export default ___CSS_LOADER_EXPORT___;
