// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AdviserContainer-module__wrapper___xLxoH{position:sticky;top:70px;width:60%;z-index:4;min-height:60px;margin:-40px auto 25px;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center}@media(max-width: 540px){.AdviserContainer-module__wrapper___xLxoH{margin-top:0;margin-top:initial}}.AdviserContainer-module__blurWrapper___BG96e{position:absolute;padding:0 10px}.AdviserContainer-module__blurWrapper___BG96e::before{content:\"\";position:absolute;background-color:#000;z-index:-1;width:100%;height:110%;left:0;-webkit-filter:blur(12px);filter:blur(12px)}", "",{"version":3,"sources":["webpack://./src/features/adviser/AdviserContainer.module.scss"],"names":[],"mappings":"AAAA,0CACE,eAAA,CACA,QAAA,CACA,SAAA,CACA,SAAA,CACA,eAAA,CACA,sBAAA,CACA,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,uBAAA,CAAA,oBAAA,CAAA,sBAAA,CAEA,yBAVF,0CAWI,YAAA,CAAA,kBAAA,CAAA,CAIJ,8CACE,iBAAA,CACA,cAAA,CAEA,sDACE,UAAA,CACA,iBAAA,CACA,qBAAA,CACA,UAAA,CACA,UAAA,CACA,WAAA,CACA,MAAA,CACA,yBAAA,CAAA,iBAAA","sourcesContent":[".wrapper {\n  position: sticky;\n  top: 70px;\n  width: 60%;\n  z-index: 4;\n  min-height: 60px; // 2 lines of text\n  margin: -40px auto 25px;\n  display: flex;\n  justify-content: center;\n\n  @media (width <= 540px) {\n    margin-top: unset;\n  }\n}\n\n.blurWrapper {\n  position: absolute;\n  padding: 0 10px;\n\n  &::before {\n    content: '';\n    position: absolute;\n    background-color: black;\n    z-index: -1;\n    width: 100%;\n    height: 110%;\n    left: 0;\n    filter: blur(12px);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "AdviserContainer-module__wrapper___xLxoH",
	"blurWrapper": "AdviserContainer-module__blurWrapper___BG96e"
};
export default ___CSS_LOADER_EXPORT___;
