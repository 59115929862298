// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GraphNew-module__wrapper___pSz_E{height:100%;position:relative}", "",{"version":3,"sources":["webpack://./src/features/cyberlinks/GraphNew/GraphNew.module.scss"],"names":[],"mappings":"AAIA,kCACE,WAAA,CACA,iBAAA","sourcesContent":[".cosmographStyle {\n  //   background-color: red;\n}\n\n.wrapper {\n  height: 100%;\n  position: relative;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "GraphNew-module__wrapper___pSz_E"
};
export default ___CSS_LOADER_EXPORT___;
