// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TimeFooter-module__wrapper___ViTns{display:-webkit-box;display:-ms-flexbox;display:flex;gap:5px;font-size:20px}.TimeFooter-module__wrapper___ViTns:hover>span{color:var(--primary-color)}.TimeFooter-module__utcTime___Bjoex{color:var(--blue-light)}", "",{"version":3,"sources":["webpack://./src/features/TimeFooter/TimeFooter.module.scss"],"names":[],"mappings":"AAAA,oCACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,OAAA,CACA,cAAA,CAGE,+CACE,0BAAA,CAMN,oCACE,uBAAA","sourcesContent":[".wrapper {\n  display: flex;\n  gap: 5px;\n  font-size: 20px;\n\n  &:hover {\n    >span {\n      color: var(--primary-color);\n    }\n  }\n\n}\n\n.utcTime {\n  color: var(--blue-light);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "TimeFooter-module__wrapper___ViTns",
	"utcTime": "TimeFooter-module__utcTime___Bjoex"
};
export default ___CSS_LOADER_EXPORT___;
