// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".KeywordButton-module__keywordBtn___ltyak{color:var(--blue-light);width:60px;font-size:16px;padding:3px 15px;text-align:center;border-radius:15px;border:1px solid rgba(31,203,255,.2)}.KeywordButton-module__keywordBtn___ltyak:hover{opacity:.7}.KeywordButton-module__keywordBtn___ltyak.KeywordButton-module__isKeyword___nz2eW{width:auto;width:initial;position:relative}.KeywordButton-module__keywordBtn___ltyak.KeywordButton-module__isKeyword___nz2eW:hover::after{content:\"x\";position:absolute;background-color:var(--red);color:var(--grayscale-primary);left:2px;right:2px;top:2px;bottom:2px;border-radius:15px}", "",{"version":3,"sources":["webpack://./src/features/studio/components/KeywordButton/KeywordButton.module.scss"],"names":[],"mappings":"AAEA,0CACE,uBAAA,CACA,UAAA,CAEA,cAAA,CACA,gBAAA,CACA,iBAAA,CAEA,kBAAA,CACA,oCAAA,CAEA,gDACE,UAAA,CAGF,kFACE,UAAA,CAAA,aAAA,CACA,iBAAA,CAEE,+FACE,WAAA,CACA,iBAAA,CACA,2BAAA,CACA,8BAAA,CACA,QA1BG,CA2BH,SA3BG,CA4BH,OA5BG,CA6BH,UA7BG,CA8BH,kBAAA","sourcesContent":["$position: 2px;\n\n.keywordBtn {\n  color: var(--blue-light);\n  width: 60px;\n\n  font-size: 16px;\n  padding: 3px 15px;\n  text-align: center;\n\n  border-radius: 15px;\n  border: 1px solid rgba(31, 203, 255, 0.2);\n\n  &:hover {\n    opacity: 0.7;\n  }\n\n  &.isKeyword {\n    width: unset;\n    position: relative;\n    &:hover {\n      &::after {\n        content: 'x';\n        position: absolute;\n        background-color: var(--red);\n        color: var(--grayscale-primary);\n        left: $position;\n        right: $position;\n        top: $position;\n        bottom: $position;\n        border-radius: 15px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"keywordBtn": "KeywordButton-module__keywordBtn___ltyak",
	"isKeyword": "KeywordButton-module__isKeyword___nz2eW"
};
export default ___CSS_LOADER_EXPORT___;
