// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UseDesktopVersionBlock-module__wrapper___GoknF{min-height:200px;padding:0 10px}.UseDesktopVersionBlock-module__wrapper___GoknF>div{height:100%}", "",{"version":3,"sources":["webpack://./src/containers/application/UseDesktopVersionBlock/UseDesktopVersionBlock.module.scss"],"names":[],"mappings":"AACA,gDACE,gBAAA,CACA,cAAA,CAEA,oDACE,WAAA","sourcesContent":["// fix\n.wrapper {\n  min-height: 200px;\n  padding: 0 10px;\n\n  > div {\n    height: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "UseDesktopVersionBlock-module__wrapper___GoknF"
};
export default ___CSS_LOADER_EXPORT___;
