// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IconsNumber-module__wrapper___Es24T{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;white-space:nowrap}.IconsNumber-module__icon___TNks_{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:horizontal;-webkit-box-direction:normal;-ms-flex-direction:row;flex-direction:row;margin-left:3px}.IconsNumber-module__icon___TNks_ .IconsNumber-module__vertical___JdMx_{-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column}.IconsNumber-module__tooltipWrapper___XL_Cc{white-space:nowrap}.IconsNumber-module__hydrogenPlaceholder___hdN47{display:-webkit-box;display:-ms-flexbox;display:flex;width:30px;height:20px;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center}", "",{"version":3,"sources":["webpack://./src/components/IconsNumber/IconsNumber.module.scss"],"names":[],"mappings":"AAAA,qCACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CACA,kBAAA,CAGF,kCACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,6BAAA,CAAA,4BAAA,CAAA,sBAAA,CAAA,kBAAA,CACA,eAAA,CAEA,wEACE,2BAAA,CAAA,4BAAA,CAAA,yBAAA,CAAA,qBAAA,CAIJ,4CACE,kBAAA,CAGF,iDACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,UAAA,CACA,WAAA,CACA,uBAAA,CAAA,oBAAA,CAAA,sBAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA","sourcesContent":[".wrapper {\n  display: flex;\n  align-items: center;\n  white-space: nowrap;\n}\n\n.icon {\n  display: flex;\n  flex-direction: row;\n  margin-left: 3px;\n\n  .vertical {\n    flex-direction: column;\n  }\n}\n\n.tooltipWrapper {\n  white-space: nowrap;\n}\n\n.hydrogenPlaceholder {\n  display: flex;\n  width: 30px;\n  height: 20px;\n  justify-content: center;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "IconsNumber-module__wrapper___Es24T",
	"icon": "IconsNumber-module__icon___TNks_",
	"vertical": "IconsNumber-module__vertical___JdMx_",
	"tooltipWrapper": "IconsNumber-module__tooltipWrapper___XL_Cc",
	"hydrogenPlaceholder": "IconsNumber-module__hydrogenPlaceholder___hdN47"
};
export default ___CSS_LOADER_EXPORT___;
