// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Level-module__text___cIeNa{color:var(--grayscale-dark)}.Level-module__value___dwCaE{color:var(--blue-light)}", "",{"version":3,"sources":["webpack://./src/pages/robot/Layout/RobotHeader/ui/Level/Level.module.scss"],"names":[],"mappings":"AAAA,4BACE,2BAAA,CAEF,6BACE,uBAAA","sourcesContent":[".text {\n  color: var(--grayscale-dark);\n}\n.value {\n  color: var(--blue-light);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": "Level-module__text___cIeNa",
	"value": "Level-module__value___dwCaE"
};
export default ___CSS_LOADER_EXPORT___;
