// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Layout-module__header___O9AKs{margin:20px auto 30px;width:420px}@media(max-width: 480px){.Layout-module__header___O9AKs{width:auto;width:initial}.Layout-module__header___O9AKs>div{width:100vw}}", "",{"version":3,"sources":["webpack://./src/pages/teleport/Layout/Layout.module.scss"],"names":[],"mappings":"AAEA,+BACE,qBAAA,CACA,WAAA,CAEA,yBAJF,+BAKI,UAAA,CAAA,aAAA,CAEA,mCACE,WAAA,CAAA","sourcesContent":["$mobile-breakpoint: 480px;\n\n.header {\n  margin: 20px auto 30px;\n  width: 420px;\n\n  @media (width <=$mobile-breakpoint) {\n    width: unset;\n\n    > div {\n      width: 100vw;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "Layout-module__header___O9AKs"
};
export default ___CSS_LOADER_EXPORT___;
