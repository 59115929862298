// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VoiceInteraction-module__voiceInteraction___Rtnp3{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;margin-left:10px}.VoiceInteraction-module__voiceInteraction___Rtnp3 button{width:100px;min-width:0;min-width:initial}.VoiceInteraction-module__voiceInteraction___Rtnp3 p{margin-left:10px;font-style:italic}", "",{"version":3,"sources":["webpack://./src/features/sense/ui/VoiceInteraction/VoiceInteraction.module.scss"],"names":[],"mappings":"AAAA,mDACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CACA,gBAAA,CAEA,0DACE,WAAA,CACA,WAAA,CAAA,iBAAA,CAsBF,qDACE,gBAAA,CACA,iBAAA","sourcesContent":[".voiceInteraction {\n  display: flex;\n  align-items: center;\n  margin-left: 10px;\n\n  button {\n    width: 100px;\n    min-width: unset;\n  }\n\n  // button {\n  //   // background-color: #4CAF50;\n  //   color: white;\n  //   border: none;\n  //   padding: 10px 20px;\n  //   text-align: center;\n  //   text-decoration: none;\n  //   display: inline-block;\n  //   font-size: 16px;\n  //   margin: 4px 2px;\n  //   cursor: pointer;\n  //   border-radius: 4px;\n\n  //   &:disabled {\n  //     background-color: #ccc;\n  //     cursor: not-allowed;\n  //   }\n  // }\n\n  p {\n    margin-left: 10px;\n    font-style: italic;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"voiceInteraction": "VoiceInteraction-module__voiceInteraction___Rtnp3"
};
export default ___CSS_LOADER_EXPORT___;
